<template>
  <div
    v-if="getIsOnlyTopRang && rang < 3 || !getIsOnlyTopRang"
    class="column gap-20"
  >
    <VCheckbox
      v-if="currentPosition?.price && currentPosition?.quantity"
      :name="String(Math.random())"
      :id="String(Math.random())"
      class-label="label label-border-gray"
      class-form="form-checkbox form__control-min-height"
      :value="modalChecked"
      v-model="modalChecked"
      @input="handleClick"
    >
      <template #default="slotProps">
        <label
          :for="slotProps.for"
          :class="slotProps.class"
        >
          <div class="flex">
            <div class="mt-10 mb-10">
              <span class="price mr-10">Цена за ед.:</span>{{ price }} руб
            </div>

            <span class="small-text" v-if="currentPosition.quantity > 1">
              Сумма за {{ currentPosition.quantity }} {{ getMeasurement }}.: {{ sum }}
            </span>
          </div>
        </label>
      </template>
    </VCheckbox>

    <div v-else>
      Ставка не создана
    </div>

    <ButtonIcon
      v-if="rang"
      text="Значение критериев"
      class="btn-reset"
      classes="hover--green self-start"
      icon="TableSmall"
      @click="onCriterion"
    />

    <div
      v-if="rang"
      :class="['rang', `color-${rang > 2 ? 3: rang}`]"
    >
      Ранг {{ rang }}
    </div>
  </div>

  <div v-else>
    Заявка участника не проходит по критерию выбора
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits, onMounted, ref, watch, reactive } from 'vue'
import { decoratorProps } from '@/mixin/props'
import VCheckbox from '@/components/ui/form/Checkbox/VCheckbox'
import { useStore } from 'vuex'
import { useSelectedItems } from '@/use/Form/Organizer/useSelectedItems'
import { formatCurrency } from '@/utils/format/number'
import ButtonIcon from '@/components/ui/buttons/ButtonIcon'
import { transformPositionParametersForTableModal } from '@/utils/model/Position/transform'

const modalChecked = ref(false)
const currentOffer = reactive({})
const store = useStore()
const emit = defineEmits(decoratorProps.emits)
const props = defineProps({
  ...decoratorProps.props
})
const onCriterion = () => {
  const parameters = transformPositionParametersForTableModal(currentPosition.value.parameters)

  emit('decorator', {
    name: 'open-modal',
    modalName: 'table-criterion',
    user: {
      organization_name: props.headCell?.user?.organization_name
    },
    position: {
      parameters,
      characteristic: currentPosition.value.characteristic
    }
  })
}

const { selectedItems } = useSelectedItems()

const getOffer = computed(() => props.headCell?.offers.find(offer => offer.position_id === props.row.id))
const offerId = computed(() => getOffer.value?.id)
const currentPosition = computed(() => getOffer.value?.answers?.position)
const getIsOnlyTopRang = computed(() => store.getters['offer/getIsOnlyTopRang'])
const getBeforeResultPosition = computed(() => store.getters['offer/getBeforeResultPosition'])

const rang = computed(() => {
  if (!offerId.value) return null

  const resultPosition = getBeforeResultPosition.value[props.row.id]
  if (!resultPosition) return null

  const resultIndex = resultPosition.findIndex(item => item.offers[0].id === offerId.value)

  if (resultIndex === -1) return null
  return resultIndex + 1
})

const getSelItems = computed(() => store.getters['offer/getSelItems'])

watch(getSelItems, () => {
  modalChecked.value = !!getSelItems.value.includes(currentOffer.id)
})

onMounted(() => {
  if (!getOffer.value) return

  Object.keys(getOffer.value).forEach(key => {
    currentOffer[key] = getOffer.value[key]
  })

  modalChecked.value = !!getSelItems.value.includes(currentOffer.id)
})

const handleClick = () => {
  selectedItems([currentOffer.id])
}

const price = computed(() => formatCurrency(currentPosition.value.price))
const sum = computed(() => formatCurrency(currentPosition.value.quantity * currentPosition.value.price))

const getMeasurement = computed(() => {
  return currentPosition.value?.measurement?.name || 'шт'
})
</script>

<style scoped lang="scss">
@import "~@/styles/_variables.scss";

.flex {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.price {
  font-size: 14px;
}

.small-text {
  font-size: 14px;
  color: $color-gray;
}

.width-30 {
  width: 30px;
}

.rang {
  position: relative;
  left: 0;
  bottom: 0;

  width: 62px;
  height: 32px;

  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  color: white;
}

.color-1 {
  background-color: $color-green;
}

.color-2 {
  background-color: #FFD687;
  color: $color-primary;
}

.color-3 {
  background-color: $color-gray;
}
</style>
