import { reactive, watch } from 'vue'
import { useForm } from '@/use/Base/useForm'
import { helpers } from '@vuelidate/validators'
import { requiredValid } from '@/utils/validator/fields'
import { useStore } from 'vuex'

export function useOrganizerSummarizingModalChangePosition (emit, getIsOpen) {
  const store = useStore()
  const formData = reactive({
    first: null,
    second: null,
    third: null
  })

  watch(getIsOpen, () => {
    if (getIsOpen.value) {
      const primaryWinners = store.getters['offer/getPrimaryWinners']
      Object.keys(formData).forEach((key, idx) => {
        formData[key] = primaryWinners[idx]
      })
    }
  })

  const uniqueVal = (val, index) => {
    const keys = Object.keys(formData)
    let isFalse = false
    keys.forEach((key, idx) => {
      if (idx === index) return
      if (!val && val !== 0) return
      if (+val === +formData[key]) isFalse = true
    })
    return !isFalse
  }

  const rules = {
    first: {
      ...requiredValid,
      unique: helpers.withMessage('Участники не могут повторяться', (val) => uniqueVal(val, 0))
    },
    second: {
      unique: helpers.withMessage('Участники не могут повторяться', (val) => uniqueVal(val, 1))
    },
    third: {
      unique: helpers.withMessage('Участники не могут повторяться', (val) => uniqueVal(val, 2))
    }
  }

  const formAction = () => {
    const keys = Object.keys(formData)
    const arr = keys.map(key => formData[key])
    store.commit('offer/setPrimaryWinners', arr)
    emit('close', arr)
  }

  return {
    ...useForm(rules, formData, formAction)
  }
}
