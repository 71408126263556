<script setup>
import VIcon from '@/components/ui/icon/VIcon'
import VOrganizerSummarizingModalChangePosition from '@/components/views/Organizer/Summarizing/Modal/VOrganizerSummarizingModalChangePosition'
import useVModal from '@/use/Base/useVModal'

const {
  openVModal,
  closeVModal,
  showVModal
} = useVModal()

</script>

<template>
  <VIcon
    class="table-menu mr-20"
    icon-name="MenuLot"
    width="20"
    height="35"
    @click="openVModal"
  />

  <teleport to="body">
      <VOrganizerSummarizingModalChangePosition
        :is-open="showVModal"
        @close="closeVModal"
        @closeNon="closeVModal"
      />
  </teleport>
</template>

<style scoped>
.table-menu {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
</style>
