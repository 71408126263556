<template>
  <div class="color-cell">
    <span :class="['color-cell', getCondition ? 'success' : 'fail']">{{ getDecription }}</span>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import { useRoute } from 'vue-router'
import { decoratorProps } from '@/mixin/props'

const props = defineProps({
  ...decoratorProps.props
})

const route = useRoute()

const CHANGE_LOG_GREEN_STATUS = [
  1, 3
]
// journal color
const getCondition = computed(() => {
  if (route.path.includes('journal')) {
    return props.row.status?.id === 1
  } else if (route.path.includes('changelog')) {
    return CHANGE_LOG_GREEN_STATUS.includes(props.row[props.headCell.value]?.id)
  } else {
    return props.row?.status === 'success'
  }
})

const getDecription = computed(() => {
  if (route.path.includes('journal')) {
    return props.row[props.headCell.value]?.name
  } else if (route.path.includes('changelog')) {
    return props.row[props.headCell.value]?.name
  } else {
    return props.row[props.headCell.value]
  }
})
</script>

<style scoped lang="scss">
@import "~@/styles/_variables.scss";
.color-cell {
  &.success {
    color: $color-green;
  }

  &.fail {
    color: $color-red;
  }
}
</style>
