<template>
    <td
      class="td-relative"
      :colspan="headRow.length"
    >
      <div class="table-filter">
        <div
          class=" td-relative transition"
          :style="'left:' + getLeftMarginFilter + 'px'"
        >
          <VTableFilter
            :positions="getPositions"
          />
        </div>
        <VOnlyTopRangCheckbox
          class="item-visible transition"
          :style="'left:' + getLeftMarginCheckbox + 'px'"
        />
      </div>
  </td>
</template>
<script setup>
import VTableFilter from '@/components/ui/table-filter/VTableFilter'
import VOnlyTopRangCheckbox from '@/components/ui/form/Checkbox/Modification/VOnlyTopRangCheckbox'
import { computed, defineProps, ref, watch } from 'vue'
import { useStore } from 'vuex'

defineProps({
  row: {
    type: Object,
    required: false
  },
  headRow: {
    type: Array,
    required: false
  }
})

const store = useStore()
const DEFAULT_MARGIN_RIGHT = 250
const leftMarginCheckbox = ref(0)
const leftMarginFilter = ref(0)

const getScrollTable = computed(() => store.getters['offer/getScroll'])
const getPositions = computed(() => store.getters['offer/getActivePositions'])
const getLeftMarginCheckbox = computed(() => leftMarginCheckbox.value)
const getLeftMarginFilter = computed(() => leftMarginFilter.value)
const getPositionCheckbox = () => getScrollTable.value.width - DEFAULT_MARGIN_RIGHT

watch(getScrollTable, () => {
  leftMarginCheckbox.value = getPositionCheckbox() + getScrollTable.value.scrollLeft
  leftMarginFilter.value = getScrollTable.value.scrollLeft
}, { deep: true, immediate: true })
</script>

<style scoped>
.table-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.td-relative {
  position: relative;
}

.transition {
  transition: left 300ms ease;
}

.item-visible {
  position: absolute;
}
</style>
