<template>
  <div class="input-cell">
    <input
      v-model="input"
      :disabled="headCell.decorator.isDisabled"
      :max="headCell.decorator.max"
      :type="headCell.decorator.type || 'text'"
      :maxlength="headCell.decorator.maxLength"
      :class="['field-cell', headCell.decorator.size, isErrors ? 'error' : '']"
    >
    <small v-if="isErrors">{{ errorMsg }}</small>
  </div>
</template>

<script setup>
import { decoratorProps } from '@/mixin/props'
import { computed, defineEmits, defineProps, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { inputRangeMaxMin } from '@/utils/utils'

defineEmits([...decoratorProps.emits])

const props = defineProps({
  ...decoratorProps.props
})
const isErrors = ref(false)
const errorMsg = ref('')
const store = useStore()
const inputRef = ref(props.row[props.row[props.headCell.value] ? props.headCell.value : props.headCell.decorator.key])

const input = computed({
  get () {
    return inputRef.value
  },
  set (val) {
    const e = { value: val }
    if (props.headCell.decorator.isRange) {
      inputRangeMaxMin(e, props.headCell.decorator.range, props.headCell.decorator.maxLength)
    }
    store.commit('cell/setCurrentCell', { row: props.row, content: val, key: props.headCell.decorator.key })
    inputRef.value = val
    if (props.headCell.decorator.fn) {
      props.headCell.decorator.fn(props.row)
    }
  }
})

const getError = computed(() => {
  return store.getters['cellValidate/getErr']
})

function checkCurrentFieldOnError() {
  const item = getError.value.find(item => item.id === props.row.id && item.key === props.headCell.decorator.key)
  isErrors.value = !!item
  if (item) {
    errorMsg.value = item.msg
  }
}

watch(getError, () => {
  checkCurrentFieldOnError()
}, { deep: true })

// const handlerValueInput = (e) => {
//   if (props.headCell.decorator.isRange) {
//     inputRangeMaxMin(e.target, props.headCell.decorator.range, props.headCell.decorator.maxLength)
//   }
//   store.commit('cell/setCurrentCell', { row: props.row, content: e.target.value, key: props.headCell.decorator.key })
//   if (props.headCell.decorator.fn) {
//     props.headCell.decorator.fn(props.row)
//   }
// }
</script>

<style scoped lang="scss">
@import "~@/styles/_variables.scss";

.field-cell {
  padding: 13px 25px;
  display: flex;

  min-width: 130px;
  text-align: center;

  &.small {
    max-width: 100px;
    width: 100%;
  }

  &[type='number'] {
    -moz-appearance:textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  font-size: 16px;
  color: $color-primary;
  outline: none;
  input {
    border: 1px solid $color-primary;

    &:disabled {
      border-color: $color-gray;
    }
  }

  &.error {
    border-color: $color-red;
  }
}

</style>
