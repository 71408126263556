import { useStore } from 'vuex'

export function useSelectedItems () {
  const store = useStore()
  const selItems = store.getters['offer/getSelItems']

  const selectedItems = (items) => {
    store.commit('offer/setSelItems', items)
  }

  const createItem = (item, idx, offers, isClick) => {
    item.key = idx
    if (!isClick.value) {
      const allItems = offers.map(offer => {
        return offer.id
      })
      const items = selItems.length ? selItems : allItems
      item.selectedOffers = items
    } else {
      item.selectedOffers = []
    }
    return item
  }

  return {
    selectedItems,
    createItem
  }
}
