<template>
  <div>
    <button
      type="button"
      :disabled="!filterRows.length"
      class="btn btn-primary btn-long btn-bg-transparent btn-hover-eye"
      @click="openVModal"
    >
      <v-icon v-if="headCell.decorator.isRead && filterRows.length" icon-name="Eye" width="25" height="16" class="mr-10" />
      <v-icon v-if="!headCell.decorator.isRead && filterRows.length" icon-name="Edit" width="19" height="21" class="mr-10" />
      {{ !filterRows.length
      ? 'Нет параметров'
      : headCell.decorator.isRead
        ? 'Посмотреть'
        : changesText
      }}
    </button>

    <teleport to="body">
      <VModal
        v-if="showVModal"
        modal-inner-class="modal-table bg&#45;&#45;white"
        max-width-class="width-820"
        :confirm="false"
        @close="close"
      >
        <h3 class="modal__title mb-40">Доп. параметры</h3>
        <VTable
          v-if="rows.length"
          :head-row="headBidder"
          :rows="filterRows"

          :is-pagination="false"
          :is-min-height="false"
          :is-checkbox="false"
          class-head="border"
        />

        <div class="flex center">
          <button
            v-if="!headCell.decorator.isRead"
            class="btn btn-primary btn-bg-green mr-10"
            @click="close"
          >
            Сохранить
          </button>

          <button
            class="btn btn-primary btn-bg-transparent"
            @click="close"
          >
            {{ headCell.decorator.isRead ? 'Закрыть' :  'Отмена'}}
          </button>
        </div>
      </VModal>
    </teleport>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, onMounted, ref } from 'vue'
import { decoratorProps } from '@/mixin/props'
import useVModal from '@/use/Base/useVModal'
import VModal from '@/components/ui/modal/VModal'
import VTable from '@/components/ui/table/VTable'
import { usePositionTable } from '@/use/Form/Lot/Position/usePostitionTable'
import { checkParametersDefault } from '@/utils/lot/function.lot'
import VIcon from '@/components/ui/icon/VIcon'

defineEmits([...decoratorProps.emits])
const props = defineProps({
  ...decoratorProps.props
})

const changesText = ref('Заполнить')

const { openVModal, closeVModal, showVModal } = useVModal()
const headBidder = [
  {
    name: 'Наименование параметра',
    value: 'full_name'
  },
  {
    name: 'Значения',
    value: 'balls',
    decorator: {
      name: 'parameters',
      isBidder: true,
      isDisabled: props.headCell.decorator.isRead
    }
  },
  {
    name: 'Группа критерия',
    value: 'group_name'
  },
  {
    name: 'В/К параметра',
    value: 'coef'
  },
]

const renderTextBtn = () => {
  const withoutPrice = props.row.parameters.filter(par => (!par.hide && par.is_selected) || par.group_name === 'Стоимостной')
  if (withoutPrice.length) {
    changesText.value = 'Редактировать'
  } else {
    changesText.value = 'Заполнить'
  }
}

onMounted(renderTextBtn)

const close = () => {
  renderTextBtn()
  closeVModal()
}

const { rows } = usePositionTable(props.row)

const filterRows = computed(() => {
  return checkParametersDefault(rows)
})
</script>
