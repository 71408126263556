import { findById } from '@/utils/array'

function transformPositionParametersForTableModal (parameters) {
  return parameters.map(item => {
    let option
    let value

    if (item.type === 1) {
      option = item.options[0]
      value = '-'
    } else {
      const selectedId = item.is_selected
      if (selectedId) {
        option = findById(selectedId, item.options)
        value = option?.full_name || 'Не выбрано'
      }
    }

    const balls = option?.balls || 'Не выбрано'

    return {
      full_name: item.full_name,
      group_name: item.group_name,
      coef: item.coef,
      value,
      balls
    }
  })
}

export {
  transformPositionParametersForTableModal
}
