<template>
  <span>
    {{ textCell }}
  </span>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { decoratorProps } from '@/mixin/props'
import { getYesOrNo } from '@/utils/boolean'
import { getValueByPath } from '@/utils/object'

const props = defineProps({
  ...decoratorProps.props
})

const textCell = computed(() => {
  const key = props.headCell.decorator?.key
  if (key) {
    return getYesOrNo(props.row[key])
  }

  const path = props.headCell.decorator?.path
  if (path.length) {
    let value = getValueByPath(path, props.row)

    const handler = props.headCell.decorator?.handler
    if (handler) {
      value = handler(value)
    }

    return getYesOrNo(value)
  }

  return getYesOrNo(false)
})
</script>
