<template>
  <div class="status-button-cell">
    <button
      :class="['btn btn-primary', isClick ? 'btn-bg-green btn-bg-green-text-white' : 'btn-bg-transparent']"
      @click="toggleClick"
      :disabled="headCell.offer?.is_fake || (!isApproved && isDisabledButton) || (props.row.decorator.status === 12 || props.row.decorator.status === 10)"
    >
      <VIcon height="13" width="13" icon-name="Checked" v-if="isClick" class="mr-10"/>
      {{ isClick ? 'Утверждено' : 'Утвердить' }}
    </button>
  </div>
</template>

<script setup>
import VIcon from '@/components/ui/icon/VIcon'
import { defineProps, ref, computed } from 'vue'
import { decoratorProps } from '@/mixin/props'
import { useStore } from 'vuex'
import { useSelectedItems } from '@/use/Form/Organizer/useSelectedItems'

const props = defineProps({
  isDisabledButton: {
    type: Boolean,
    default: false
  },
  ...decoratorProps.props
})

const isClick = ref(false)
const store = useStore()
const { createItem } = useSelectedItems()

const isApproved = computed(() => {
  const approved = store.getters['offer/getApproveList']
  if (props.headCell.idx === approved[0]?.idx) return true
  else return false
})

const toggleClick = () => {
  const item = createItem(props.headCell, props.headCell.idx, props.headCell.offers, isClick)
  store.commit('offer/toggleApproveList', item)
  isClick.value = !isClick.value
}

</script>

<style lang="scss">
@import "~@/styles/_variables.scss";

.status-button-cell {
  button:hover path {
    stroke: #233F1F;
  }
}
</style>
