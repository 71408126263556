<template>
  <span v-if="headCell.isNaming">
    <b>{{ rowName }}</b>

    <br v-if="row.characteristic">
    <br v-if="row.characteristic">
    <span v-if="row.characteristic">{{ row.characteristic }}</span>

    <br v-if="row.quantity">
    <br v-if="row.quantity">
    <span v-if="row.quantity" class="quantity">{{ row.quantity }} {{ row?.measurement?.name || ' шт' }}</span>
  </span>
  <component
    v-else
    :class="[row.blacklist_at ? 'banned-cell' : '']"
    :is="row.decorator?.name[0].toUpperCase() + row.decorator?.name.slice(1) +'Cell'"
    :isDisabledButton="row.decorator.isDisabledButton"
    :row="row"
    :headCell="headCell"
    @decorator="(data) => $emit('decorator', data)"
  >
  </component>
</template>

<script>
import NdsCell from '@/components/ui/table/Decorators/Invert/VTableDecoratorNdsCell'
import StatusButton from '@/components/ui/table/Decorators/Invert/VTableDecoratorStatusButtonCell'
import PositionChecked from '@/components/ui/table/Decorators/Invert/VTableDecoratorPositionCheckedCell'
import { decoratorProps } from '@/mixin/props'
import { computed } from 'vue'

export default {
  emits: [...decoratorProps.emits],
  props: {
    ...decoratorProps.props
  },
  setup(props) {
    const rowName = computed(() => {
      const idx = props.row.idx
      if (!idx && idx !== 0) return props.row.name

      return idx + 1 + '. ' + props.row.name
    })

    return {
      rowName
    }
  },
  components: {
    NdsCell: NdsCell,
    StatusButtonCell: StatusButton,
    PositionCheckedCell: PositionChecked
  }
}

</script>

<style scoped lang="scss">
@import "~@/styles/_variables.scss";
.quantity {
  color: $color-gray;
  font-size: 14px;
}
</style>
