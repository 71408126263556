export class DefaulterNomenclatur {
  static createPrice(formData) {
    return {
      type: 1,
      hide: true,
      full_name: 'Начальная цена',
      group_name: 'Стоимостные',
      groupId: 1,
      coef: formData?.price_coef | 1,
      is_criterion: true,
      options: [
        {
          balls: formData?.price_ball | 2
        }
      ]
    }
  }
}
