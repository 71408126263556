export default class TableInject {
  // headCell includes:
  // isSelected: boolean
  // min_width: min width column

  // bodyCell includes:
  // rows: where only type NUMBER key which equal index row in table
  // object row includes:
  // classStyles = class list
  // inWindow = the object is always in view
  // items: list component
  // item = componentName and name directives on handler
  constructor({ headCell, bodyCell }) {
    this.headCell = headCell
    this.bodyCell = bodyCell
  }
}
