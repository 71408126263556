<template>
  <div :class="['form__control', classForm]" v-if="!isSwitch">
    <div class="svg-wrapper">
      <svg height="26" width="26" xmlns="http://www.w3.org/2000/svg" v-if="errors?.length || serverErrors?.length">
        <rect class="shape1" height="26" width="26" />
        <rect class="shape2" height="26" width="26" />
      </svg>
      <input
        type="checkbox"
        class="checkbox"
        :id="id"
        :disabled="disabled"
        @click="setIsCheck"
        @blur="setBlur"
        :name="name"
        :checked="getModelValue"
      >
      <slot :for="id" :class="classLabel" v-if="label"/>
      <div v-if="errors?.length" class="error-small-checkbox">
        <small v-for="err in errors" :key="err.$message" class="error-small">{{ err.$message }}</small>
      </div>
      <small v-if="description && !errors?.length" class="description">{{ description }}</small>
    </div>
  </div>

  <div :class="['form__control', classForm, isHeight ? 'mb-20' : 'form__control-not-height', isWidth ? '' : 'form__control-not-width']" v-else>
    <div class="checkbox-modified-container checkbox-modified-container-slot">
      <div class="checkbox-modified">
        <input
          type="checkbox"
          :id="id" class="checkbox-modified-input"
          @click="setIsCheck"
          @blur="setBlur"
          :name="name"
          :disabled="disabled"
          :checked="getModelValue" />
        <label :for="id" :class="['checkbox-modified-label', classLabel]"></label>
      </div>
      <slot :for="id" :class="classLabel" v-if="label"/>
    </div>

    <div v-if="errors?.length || serverErrors?.length" class="error-small-checkbox">
      <small class="error-small" v-if="errors[0]?.$message">{{ errors[0].$message }}</small>
      <small class="error-small" v-if="serverErrors?.length">{{ serverErrors[0] }}</small>
    </div>
    <small v-if="description && !errors?.length" class="description">{{ description }}</small>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { fieldProps } from '@/mixin/props'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    classLabel: {
      type: [Array, String],
      required: false,
      default: 'checkbox-label'
    },
    classForm: {
      type: [Array, String],
      required: false
    },
    isHeight: {
      type: Boolean,
      required: false,
      default: true
    },
    isWidth: {
      type: Boolean,
      required: false,
      default: true
    },
    modelValue: {
      type: [String, Boolean]
    },
    label: {
      type: Boolean,
      required: false,
      default: true
    },
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    isSwitch: {
      type: Boolean,
      required: false,
      default: false
    },
    serverErrors: {
      type: Array,
      required: false
    },
    isModelValue: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  mixins: [fieldProps],
  setup (props, { emit }) {
    const isCheck = ref(props.modelValue || false)

    const getModelValue = computed(() => {
      return props.isModelValue ? props.modelValue : props.value
    })

    watch(getModelValue, () => {
      isCheck.value = props.modelValue
    })

    const setIsCheck = () => {
      isCheck.value = !isCheck.value
      if (isCheck.value === true) {
        emit('update:modelValue', isCheck.value)
      } else {
        emit('update:modelValue', false)
      }
      emit('input', props.name)
      if (props.serverErrors?.length) props.serverErrors.length = 0
    }

    const setBlur = () => {
      emit('blur', props.name)
    }

    return {
      setIsCheck,
      setBlur,
      getModelValue
    }
  }
}
</script>

<style scoped lang="scss">
.svg-wrapper {
  height: 0;
  position: absolute;
  transform: translateY(50%);
  margin: 0 auto;

  & > svg {
    position: absolute;
    z-index: -1;
    top: -1px;
    bottom: -1px;
    right: -1px;
    left: -1px;
  }
}
.shape1,
.shape2 {
  stroke-dasharray: 1120;
  stroke-width: 1px;
  fill: transparent;
  stroke: red;
  border-bottom: 1px solid red;
  transition-timing-function: linear;
  transition: stroke-dashoffset 4s, stroke-dasharray 4s;
}
.shape1 {
  stroke-dashoffset: 1120;
}
.shape2 {
  stroke-dashoffset: -1120;
}
.svg-wrapper:hover .shape1,
.svg-wrapper:hover .shape2 {
  stroke-dashoffset: 0;
  stroke-dasharray: 1120;
}
</style>
