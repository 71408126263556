<template>
  <div class="hidePassword">
    <span v-if="!isShow">{{ hashPassword }}</span>
    <span v-else>{{ row[headCell.value] }}</span>

    <div @click="changeVisiblePassword">
      <VIcon
        :class="['input__eye', isShow ? 'input__eye-hidden' : '']"
        width="25"
        :height="isShow ? '24' : '19'"
        :icon-name="isShow ? 'EyeHidden' : 'Eye'"
      />
    </div>
  </div>
</template>

<script setup>
import { defineComponent, defineEmits, defineProps, onMounted, ref } from 'vue'
import { decoratorProps } from '@/mixin/props'
import VIcon from '@/components/ui/icon/VIcon'

defineEmits(decoratorProps.emits)

const props = defineProps({
  ...decoratorProps.props
})

const isShow = ref(false)
const hashPassword = ref('')
onMounted(() => {
  for (let i = 0; i < props.headCell.decorator.maxLength + 1; i++) {
    hashPassword.value += '*'
  }
})

const changeVisiblePassword = () => {
  isShow.value = !isShow.value
}

defineComponent({
  VIcon
})
</script>

<style scoped lang="scss">
.hidePassword {
  max-width: 330px;
  width: 100%;

  display: flex;
  justify-content: space-between;

  position: relative;

  & svg {
    position: relative;
    //margin-left: 10px;
    top: 0;
    right: 0;
  }

  & .input__eye {
    margin-left: 10px;

    width: 25px;
    height: 25px;
  }
}
</style>
