import { computed, onMounted, onUnmounted, reactive, watch } from 'vue'
import store from '@/store'
import { useRoute } from 'vue-router'
import { DefaulterNomenclatur } from '@/modules/defaulter/DefaulterNomenclatur'
import { getIndexByFullName, spliceBy, toNum } from '@/utils/utils'

export function usePositionTable (formData) {
  const route = useRoute()

  const head = [
    {
      name: 'Наименование параметра',
      value: 'full_name'
    },
    {
      name: 'Группа критерия',
      value: 'group_name'
    },
    {
      name: 'В/К параметра',
      value: 'coef',
      decorator: {
        name: 'input',
        size: 'small',
        isRange: true,
        range: {
          size: {
            min: 0,
            max: 1000,
            pos: [0, 1, 2]
          }
        },
        maxLength: 4
      }
    },
    {
      name: 'Баллы',
      value: 'balls',
      decorator: {
        name: 'modal'
      }
    }
  ]
  const headBidder = [
    {
      name: 'Наименование параметра',
      value: 'full_name'
    },
    {
      name: 'Группа критерия',
      value: 'group_name'
    },
    {
      name: 'В/К параметра',
      value: 'coef'
    },
    {
      name: 'Баллы',
      value: 'balls',
      decorator: {
        name: 'modal',
        isBidder: true
      }
    }
  ]

  const getCellTextContent = computed(() => {
    return store.getters['cell/getCurrentCell']
  })

  watch(getCellTextContent, () => {
    const fullName = getCellTextContent.value.row.full_name
    const content = getCellTextContent.value.content

    formData.parameters.forEach(par => {
      if (par.full_name === fullName) {
        par.non_coef = content
      }
    })

    if (fullName === 'Количество') {
      formData.quantity_coef = toNum(content)
    }

    if (fullName === 'Начальная цена') {
      formData.price_coef = toNum(content)
    }
  })

  const getQuantityCoef = computed(() => {
    return formData.is_quantity_coef
  })

  watch(getQuantityCoef, () => {
    if (formData.is_quantity_coef) {
      formData.parameters.push(createQuantity())
      rows.push(createQuantity())
    } else {
      const idx = rows.findIndex(row => row.full_name === 'Количество')
      const idxP = formData.parameters.findIndex(row => row.full_name === 'Количество')
      rows.splice(idx, 1)
      formData.parameters.splice(idxP, 1)
    }
  })

  watch(formData.parameters, () => renderNew())

  const pushItemRows = () => {
    formData.parameters.forEach(par => {
      if (par.is_characteristic) return
      rows.push(par)
    })
  }

  const pushDefaultValue = () => {
    formData.parameters.push(DefaulterNomenclatur.createPrice(formData))
    rows.push(DefaulterNomenclatur.createPrice(formData))
  }

  const renderCurrentItem = () => {
    if (formData.parameters === null) {
      formData.parameters = reactive([])
    }
    if (formData.parameters.length) {
      rows.length = 0
      pushItemRows()
    } else {
      formData.parameters.forEach(par => {
        if (par.full_name === 'Начальная цена') {
          pushDefaultValue()
        }
      })
    }
  }

  const renderNew = () => {
    if (formData.parameters.length) {
      rows.length = 0

      pushItemRows()
    } else {
      pushDefaultValue()
    }
  }

  onMounted(() => {
    if (route.params.id) {
      renderCurrentItem()
    } else {
      renderNew()
    }

    if (!formData.parameters.length) {
      renderNew()
    }
  })

  onUnmounted(() => {
    formData.parameters.forEach(par => {
      if (par.non_coef) {
        par.coef = par.non_coef
      }
    })
  })
  const createQuantity = () => {
    return {
      type: 1,
      hide: true,
      full_name: 'Количество',
      group_name: 'Стоимостные',
      groupId: 1,
      coef: formData.quantity_coef | 1,
      is_criterion: true,
      options: [
        {
          balls: formData.quantity_ball | 1
        }
      ]
    }
  }

  const rows = reactive([])

  const addRows = (el) => {
    if (el.hide) return
    if (el.is_criterion) {
      rows.push(el)
    }
  }

  const changeRows = (parameter) => {
    if (parameter.hide) return
    if (!parameter.is_criterion) {
      const data = rows.filter(row => row.is_criterion)

      if (data.length) {
        rows.length = 0
        rows.push(...data)
      }
    } else {
      const candidate = rows.find(row => row.full_name === parameter.full_name)

      if (!candidate) {
        return rows.push(parameter)
      }
      rows.forEach(row => {
        if (row.full_name === parameter.full_name) {
          Object.keys(parameter).forEach(key => {
            row[key] = parameter[key]
          })
        }
      })
    }
  }

  const deleteParameter = (el) => {
    el.action = 'delete'
    el.state = 'hide'

    spliceBy(el.full_name, formData.parameters, getIndexByFullName)
  }

  return {
    changeRows,
    deleteParameter,
    head,
    headBidder,
    rows,
    addRows
  }
}
