<template>
  <div class="input-cell" v-if="headCell.decorator.options.length">
    <VSelect
      :name="headCell.name"
      :id="headCell.name"
      isCustomBorder
      :disabled="headCell.decorator.isDisabled"
      @input="handlerValueInput"
      v-model="row[headCell.decorator.key]"
      :class="['field-cell mb-0 mb-0 mh-0 border-all-black', headCell.decorator.size, isErrors ? 'error' : '', headCell.decorator.isDisabled && 'border-gray']"
      :options="headCell.decorator.options"
    />
    <small v-if="isErrors">{{ errorMsg }}</small>
  </div>
</template>

<script setup>
import { decoratorProps } from '@/mixin/props'
import { computed, defineEmits, defineProps, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { inputRangeMaxMin } from '@/utils/utils'
import VSelect from '@/components/ui/form/VSelect'

defineEmits([...decoratorProps.emits])

const props = defineProps({
  ...decoratorProps.props
})

const isErrors = ref(false)
const errorMsg = ref('')
const store = useStore()

const getError = computed(() => {
  return store.getters['cellValidate/getErr']
})

function checkCurrentFieldOnError() {
  const item = getError.value.find(item => item.id === props.row.id && item.key === props.headCell.decorator.key)
  isErrors.value = !!item
  if (item) {
    errorMsg.value = item.msg
  }
}

watch(getError, () => {
  checkCurrentFieldOnError()
}, { deep: true })

const handlerValueInput = (e, val) => {
  if (props.headCell.decorator.isRange) {
    inputRangeMaxMin(e.target, props.headCell.decorator.range, props.headCell.decorator.maxLength)
  }

  store.commit('cell/setCurrentCell', { row: props.row, content: val, key: props.headCell.decorator.key })
  if (props.headCell.decorator.fn) {
    props.headCell.decorator.fn(props.row)
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/_variables.scss";

.field-cell {
  width: 150px;

  &.small {
    max-width: 100px;
    width: 100%;
  }

  &.error {
    border-color: $color-red;
  }
}

.border-gray {
  border-color: $color-gray;
}

.mh-0 {
  min-height: auto;
}
</style>
