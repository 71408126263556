<script setup>
import { ref, defineProps, computed, watch, reactive, onMounted } from 'vue'
import VSelect from '@/components/ui/form/VSelect'
import VIcon from '@/components/ui/icon/VIcon'
import { useStore } from 'vuex'

const isOpen = ref(false)
const props = defineProps({
  positions: {
    type: Array,
    required: true
  }
})
const store = useStore()

const getPositions = computed(() => props.positions)
const getAllPositions = computed(() => store.getters['offer/getPositions'])
const select = reactive([])
watch(getPositions, () => {
  select.length = 0
  select.push(...getPositions.value)
})

onMounted(() => {
  if (!getPositions.value.length) return
  select.length = 0
  select.push(...getPositions.value)
})

const handlerDelete = (val) => {
  store.commit('offer/deleteActivePosition', val)
}

const handlerInput = (_, val) => {
  store.commit('offer/addActivePositions', val)
}

const toggleOpen = () => {
  isOpen.value = !isOpen.value
}
</script>

<template>
  <div>
    <button
      class="btn btn-long btn--filter not-click mt-10 mb-10"
      @click="toggleOpen"
    >
      <VIcon icon-name="Filter" width="48" height="48" class="mr-20"/>
      <b>Фильтр по позициям</b>
      <VIcon class="rotate ml-20" width="15" height="6" icon-name="AccordionArrow"/>
    </button>

    <div
      v-if="isOpen"
      class="model--select"
    >
      <VSelect
        name="filterPos"
        id="filterPos"
        v-model="select"
        :options="getAllPositions"
        multiple
        key-record="id"
        track-by="id"
        placeholder="Фильтр по позициям"
        class="mb-0"
        class-form="form__control-min-height"
        @remove="handlerDelete"
        @input="handlerInput"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "~@/styles/_variables.scss";
.mr-5 {
  margin-right: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.btn--filter {
  padding: 0 15px;

  display: flex;
  align-items: center;
  max-width: none;
  width: auto;

  background: $color-yellow;
}

.model--select {
  padding: 20px;

  position: absolute;
  left: 330px;
  top: 10px;
  z-index: 2;

  display: flex;
  background: $color-yellow;

  width: 360px;
}

.rotate {
  transform: rotate(180deg);
}
</style>
