export function getValueByPath (path, obj) {
  let currentValue = obj

  for (const key of path) {
    currentValue = currentValue[key]

    if (!currentValue && typeof currentValue !== 'boolean') return null
  }

  return currentValue
}
