<template>
    <th
      class="table__cell table__cell-checkbox"
      v-if="isCheckbox">
      <VCheckbox
        id="allItemTable"
        name="allItemTable"
        class-form="table__checkbox"
        :value="isAllSelectedItems"
        :is-model-value="false"
        class-label="label label-border-gray"
        @input="setAllActiveItems"
      >
        <template #default="slotProps">
          <label
            :for="slotProps.for"
            :class="slotProps.class"></label>
        </template>
      </VCheckbox>
    </th>

    <th
      :class="['table__cell table__cell--head', modification.componentName ? 'table__cell--head-modification' : '']"
      v-for="headCell in headRow"
      :key="headCell.name"
      :style="modification ? modification.styles : {}"
      @click="headCell.filter && setFilter(headCell.sort || headCell.value)">
      <span
        :class="[
          'table__cell-span--head',
          filterName === headCell.value ? 'filter' : '',
          headCell.subName? 'table__cell--flex' : ''
          ]"
        :style="headCell.width ? 'width:' + headCell.width : ''"
      >
        {{ headCell.name }}

        <span class="table__cell--sub mt-10">{{ headCell.subName?.length > 17 ? headCell.subName?.slice(0, 17) + '...' : headCell.subName  }}</span>

        <VIcon
          width="11"
          height="7"
          icon-name="Arrow"
          v-if="headCell.filter"
          style="width: 30px;"
        />
      </span>

      <component
        v-if="modification.componentName && !headCell.isNoneComponent"
        :is="modification.componentName"
      />
    </th>
</template>

<script>
import VTableMenu from '@/components/ui/table-menu/VTableMenu'
import VCheckbox from '@/components/ui/form/Checkbox/VCheckbox'
import VIcon from '@/components/ui/icon/VIcon'

export default {
  emits: ['active', 'filter'],
  props: {
    headRow: {
      type: Array,
      required: true
    },
    filterName: {
      type: String,
      required: false
    },
    isCheckbox: {
      type: Boolean,
      required: true
    },
    isAllSelectedItems: {
      type: Boolean,
      required: false
    },
    modification: {
      type: Object,
      required: false
    }
  },
   setup (props, { emit }) {
     const setAllActiveItems = () => {
       emit('active')
     }

     const setFilter = (filterName) => {
       emit('filter', filterName)
     }

     return {
       setAllActiveItems,
       setFilter
     }
   },
  components: {
    VIcon,
    VCheckbox,
    VTableMenu
  }
}
</script>
