<template>
  <div class="link-cell">
    <span class="link-cell__target" @click="toLink">{{ row[headCell.value] }}</span>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import { decoratorProps } from '@/mixin/props'

const emits = defineEmits(decoratorProps.emits)
const props = defineProps({
  ...decoratorProps.props
})

const toLink = () => {
  emits('decorator', { name: 'link', item: props.row })
}
</script>

<style scoped lang="scss">
@import "~@/styles/_variables.scss";
.link-cell {
  &__target {
    position: relative;
    cursor: pointer;

    &::before,
    &::after {
      content: "";
      position: absolute;
      bottom: -3px;
      z-index: 1;

      width: 0;
      height: 1px;
      background-color: $color-primary;

      transition: width .3s ease;
    }

    &::before {
      right: 50%;
    }

    &::after {
      left: 50%;
    }

    &:hover {
      left: 0;
      &::before {
        width: 50%;
      }

      &::after {
        width: 50%;
      }
    }
  }
}
</style>
