<template>
  <div :class="['icon-cell', headCell.decorator?.position]">
    <VIcon
      @click="toLink"
      :icon-name="row[headCell.decorator.iconName] || 'Rival'"
      width="25"
      height="25"
    />
    <span class="icon-cell">{{ row[headCell.value] }}</span>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import { decoratorProps } from '@/mixin/props'
import VIcon from '@/components/ui/icon/VIcon'

const emits = defineEmits(decoratorProps.emits)
const props = defineProps({
  ...decoratorProps.props
})

const toLink = () => {
  emits('decorator', { name: 'icon-click', item: props.row })
}
</script>

<style scoped lang="scss">
@import "~@/styles/_variables.scss";
.icon-cell {
  display: flex;
  align-items: center;

  & svg {
    margin-right: 10px;
    margin-left: 0;

    cursor: pointer;
  }

  &.right {
    & svg {
      margin-right: 0;
      margin-left: 10px;

      order: 1;
    }
  }
}
</style>
