<script>
import VSelect from '@/components/ui/form/VSelect'
import VModal from '@/components/ui/modal/VModal'
import { computed, onMounted, reactive, watch } from 'vue'
import {
  useOrganizerSummarizingModalChangePosition
} from '@/use/Form/Organizer/useOrganizerSummarizingModalChangePosition'
import { useStore } from 'vuex'

export default {
  name: 'VOrganizerSummarizingModalChangePosition',
  emits: ['close', 'closeNon'],
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const changeListPosition = reactive([])

    const getIsOpen = computed(() => {
      return props.isOpen
    })

    const getOffers = computed(() => {
      return store.getters['offer/getOffersWithoutFakeBidder']
    })

    watch(getOffers, async () => {
      await pushEditListPosition(getOffers.value)
    })
    const pushEditListPosition = async (offers) => {
      offers.forEach((offer, idx) => {
        const item = {
          ...offer,
          name: `Ставка ${idx + 1} от участника ${offer.user?.name}`,
          id: idx
        }

        if (!item.is_fake) {
          changeListPosition.push(item)
        }
      })
    }

    onMounted(() => pushEditListPosition(getOffers.value))

    const cancel = () => {
      emit('closeNon')
    }

    return {
      changeListPosition,
      cancel,
      ...useOrganizerSummarizingModalChangePosition(emit, getIsOpen)
    }
  },
  components: {
    VSelect,
    VModal
  }
}
</script>

<template>
  <VModal
    v-if="isOpen"
    @close="cancel"
  >
    <h4 class="modal__title mb-40">РЕДАКТИРОВАТЬ МЕСТА</h4>
    <form class="form" @submit.prevent="onSubmit">
      <div class="form__inner">
        <VSelect
          name="first"
          id="first"
          v-model="formData.first"
          :options="changeListPosition"
          classes="select-border-bottom"
          class="input-long"
          :errors="v$.first.$errors"
          @input="validateField"
          placeholder="1 место"
        />
        <VSelect
          v-if="changeListPosition.length > 1"
          name="seconds"
          id="seconds"
          v-model="formData.second"
          :options="changeListPosition"
          classes="select-border-bottom"
          class="input-long"
          :errors="v$.second.$errors"
          @input="validateField"
          placeholder="2 место"
        />

        <VSelect
          v-if="changeListPosition.length > 2"
          name="third"
          id="third"
          v-model="formData.third"
          :options="changeListPosition"
          classes="select-border-bottom"
          class="input-long"
          :errors="v$.third.$errors"
          @input="validateField"
          placeholder="3 место"
        />
        <div class="buttons-wrap center">
          <button
            class="btn btn-primary btn-primary--padding btn-bg-green"
          >
            Сохранить
          </button>

          <button
            type="button"
            class="btn btn-primary btn-primary--padding btn-bg-transparent"
            @click="cancel"
          >
            Отмена
          </button>
        </div>
      </div>
    </form>
  </VModal>
</template>
