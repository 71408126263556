import TableInject from '@/components/ui/table/Inject/TableInject'
import VTableFilter from '@/components/ui/table-filter/VTableFilter'
import VOnlyTopRangCheckbox from '@/components/ui/form/Checkbox/Modification/VOnlyTopRangCheckbox'

export default class TableOfferInject extends TableInject {
  constructor() {
    super({
      headCell: {
        styles: {
          minWidth: 280 + 'px'
        },
        componentName: 'VTableMenu'
      },
      bodyCell: {
        decorator: {
          name: 'Invert'
        },
        row: {
          4: {
            classStyles: [],
            isWindow: true,
            items: [
              {
                componentName: VTableFilter,
                onEventName: 'select'
              },
              {
                componentName: VOnlyTopRangCheckbox,
                onEventName: 'click'
              }
            ]
          }
        }

      }
    })
  }
}
