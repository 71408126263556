<script setup>
import VCheckbox from '../VCheckbox'
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const getIsOnlyTopRang = computed(() => {
  return store.getters['offer/getIsOnlyTopRang']
})

const setIsOnlyTopRang = () => {
  store.commit('offer/toggleIsOnlyTopRang')
}

</script>

<template>
  <VCheckbox
    id="isOnlyTopRang"
    name="isOnlyTopRang"
    :is-switch="true"
    :is-height="false"
    :is-width="false"
    class-label="bg-white"
    :value="getIsOnlyTopRang"
    :is-model-value="false"
    @input="setIsOnlyTopRang"
  >
    <template #default="slotProps">
      <label :for="slotProps.for" :class="slotProps.class">
        Только 1 и 2 ранг
      </label>
    </template>
  </VCheckbox>
</template>
