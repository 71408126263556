<template>
  <div v-if="row.options.length">
    <button
      v-if="!headCell.decorator.isBidder"
      type="button"
      class="btn btn-primary btn-long btn-bg-transparent"
      @click="openVModal"
    >
      <VIcon
        v-if="!checkIsBall(row.options)"
        width="18"
        height="18"
        icon-name="Plus"
        class="mr-10"
      />

      <VIcon
        v-else
        class="elements__icon mr-10"
        width="18"
        height="24"
        icon-name="Edit"
      />
      {{ checkIsBall(row.options) ? 'Изменить' :  'Добавить баллы' }}
    </button>

    <button
      v-else
      type="button"
      class="btn btn-primary btn-long btn-bg-transparent"
      @click="openVModal"
    >
      Посмотреть
    </button>

    <VModal
      v-if="showVModal"
      @close="closeVModal"
      modalInnerClass="width-885"
    >
      <h4 class="modal__title mb-40">Значения баллов</h4>
      <div
        v-if="row.type !== 1"
        class="center"
      >
          <VInput
            v-for="item in row.options"
            v-model="item.balls"
            :key="item.name"
            :name="item.name"
            is-range
            :disabled="headCell.decorator.isBidder"
            :range="range"
            type="number"
            :class-input="['input input-long input-placeholder-black input-border-bottom']"
            :placeholder="item.name"
          />
      </div>

      <div
        v-else
        class="center"
      >

        <VSelect
          v-model="row.options[0].balls"
          name="direction"
          id="direction"
          :disabled="headCell.decorator.isBidder"
          :options="directionList"
          classes="select-border-bottom"
          placeholder="Направление лучшего предложение"
        />
      </div>

      <div class="elements__buttons">
        <button type="button" class="btn btn-primary btn-bg-green" @click="closeModalWithAlert">{{ headCell.decorator.isBidder ? 'Закрыть' : 'Сохранить' }}</button>
      </div>
    </VModal>
  </div>

  <div v-else>Отсутсвуют</div>
</template>

<script setup>
import VIcon from '@/components/ui/icon/VIcon'
import { defineEmits, defineProps, reactive } from 'vue'
import { decoratorProps } from '@/mixin/props'
import useVModal from '@/use/Base/useVModal'
import VModal from '@/components/ui/modal/VModal'
import VInput from '@/components/ui/form/VInput'
import VSelect from '@/components/ui/form/VSelect'
import { Alert } from '@/extension/Alert/Alert'

defineEmits([...decoratorProps.emits])
const props = defineProps({
  ...decoratorProps.props
})

const { openVModal, closeVModal, showVModal } = useVModal()
const directionList = reactive([
  {
    name: 'Вверх',
    id: 1
  },
  {
    name: 'Вниз',
    id: 2
  }
])
const range = reactive({
  balls: {
    min: 0,
    max: 100,
    pos: [0, 1, 2]
  }
})

const checkIsBall = (opt) => {
  return opt.filter(o => o.balls !== null).length === opt.length
}

const closeModalWithAlert = () => {
  const isResult = checkIsBall(props.row.options)
  if (!isResult) {
    Alert.show('error', `Укажите баллы в поле ${props.row.full_name}`)
  }
  closeVModal()
}
</script>

<style scoped>
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
