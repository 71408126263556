<template>
  <button
    class="btn gap-10"
    :class="classes"
    type="button"
  >
    <VIcon
      :icon-name="iconName"
      :width="iconWidth"
      :height="iconHeight"
    />

    {{ text }}
  </button>
</template>

<script>
import VIcon from '@/components/ui/icon/VIcon'
export default {
  components: { VIcon },
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    },
    icon: {
      type: [String, Object],
      required: true
    },
    classes: {
      type: [Array, String],
      required: false,
      default: 'btn-primary btn-bg-transparent btn-bg-transparent-icon'
    }
  },
  computed: {
    iconName () {
      return this.icon.name || this.icon
    },
    iconWidth () {
      if (this.icon.width) return this.icon.width

      switch (this.iconName) {
        case 'Copy': return 26
        case 'TableSmall': return 19
        default: return 0
      }
    },
    iconHeight () {
      if (this.icon.height) return this.icon.height

      switch (this.iconName) {
        case 'Copy': return 21
        case 'TableSmall': return 11
        default: return 0
      }
    },
  }
}
</script>
